import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import Box from '../../common/src/components/Box'
import PricingTable from '../containers/Saas/PricingSection/pricing.style'
import Heading from '../../common/src/components/Heading';
import Step2 from './zSteps/Step2'
import Step1 from './zSteps/Step1'
import Step3 from './zSteps/Step3'
import { Steps, Button, message } from 'antd';
import Footer from '../containers/Saas/Footer/index'
const { Step } = Steps;

const steps = [
  {
    title: 'First',

  },
  {
    title: 'Second',

  },
  {
    title: 'Last',

  },
];

class Checkout extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    const { current } = this.state;
    return (
      <>
      <div style={{zIndex:'0',margin: '0 auto',paddingTop:'15vh',paddingBottom:'5%', width:'85vw', backgroundColor:'#fff !important'}}>
        <Steps current={current}>
          <Step title={'Plan'}/>  
          <Step title={'Información'}/>


        </Steps>
        <div style={{minHeight:'20vh',marginBottom:'0px', width:'100%'}} className="steps-content">{steps[current].content}
            {current === 0 && (
              <Step1 onCardClick={() => this.next()}/>
            )}
            {current === 1 && (
              <Step2 onButtonClick={() => message.info('Estamos generando tu link de pago...')} />
            )}
           
           



        </div>
        <div style={{marginBottom:'60px',width:'100%',height:'100%',display:'flex',flexDirection:'row', justifyContent:'space-evenly', alignItems:'center'}} className="steps-action">

          {current > 0 && (
            <Button style={{ margin: '0 auto' }} onClick={() => this.prev()}>
              Retroceder 
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.info('Estamos procesando tu pedido...')}>
              Done
            </Button>
          )}
          {/*current < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next()}>
              Next
            </Button>
          )*/}
        </div>
      </div>
        <Footer />
        </>
    );
  }
}

Checkout.propTypes = {
  button: PropTypes.object,
  buttonWrapper: PropTypes.object,
  title: PropTypes.object
};
Checkout.defaultProps = {
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
  buttonWrapper: {
    mt: ['25px', '50px'],
    flexBox: true,
    justifyContent: 'center',
  },
  title: {
    fontSize: ['32px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '28px',
    textAlign: 'center',
    lineHeight: '1.25',
  }
}
export default Checkout