import React from 'react'
import PricingTable from '../Saas/PricingSection/pricing.style.js'
import { Form, Input, InputNumber, Button, Select } from 'antd';

const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} es requerido',
  types: {
    email: '${label} no es un email válido',
    number: 'No es un número válido',
    string: 'Ingresa tu nombre',
  },
  number: {

  },
};

const Step3b = ({onButtonClick}) => {
  //PAGOS CON OXXO
  const onFinish = values => {
    console.log(values);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`)
  }


  return (
    <>
    

    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap'}}>
    <PricingTable >

      <p style={{textAlign:'center'}}>Paga en efectivo en cualquier Oxxo. </p>
      <p style={{textAlign:'center'}}>Haz click en el botón para generar tu información de pago. </p>
   
    <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
      <Form.Item  wrapperCol={{ ...layout.wrapperCol, offset:10 }}>
        <Button className='form-button'  type="primary" htmlType="submit">
          Pagar
        </Button>
      </Form.Item>
    </Form>

    </PricingTable>
    </div>
    
    </>
  )
}
export default Step3b