import React from 'react';

import PropTypes from 'prop-types';
import Box from '../../../../common/src/components/Box';
import Logo from '../../../../common/src/components/UIElements/Logo';
import Container from '../../../../common/src/components/UI/Container';
import FooterWrapper from './footer.style';
import LogoImage from '../../../../src/images/logo-bueno.png';
import FBLogo from '../../../../src/images/facebook.svg';
import YTLogo from '../../../../src/images/youtube.svg';
import IGLogo from '../../../../src/images/instagram.svg';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  logoStyle2,
  textStyle,
}) => {
  
  return (
    <>
    <FooterWrapper className="footer_container" >
      <Container className="footer_container" style={{backgroundColor: '#E7BACD'}}>
        <Box className="row" {...row} >
          <Box style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center'}}>
          <Logo
          style={{paddingTop:'10%', marginBottom:'-7%', paddingLeft:'5%'}} 
            href="#"
            logoSrc={LogoImage}
            title="Agency"  
            logoStyle={logoStyle}
          />
          <p {...textStyle} style={{paddingBottom:'10%'}}>Molinas Nutrición &copy; 2020</p>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo} style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center'}}>
          
          <p>Siguenos en redes sociales</p>
          <div style={{paddingLeft:'5%',display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
          <Logo 
            href="https://facebook.com/molinasnutricion/?__tn__=%2Cd%2CP-R&eid=ARDBqeu60_-Sn6RJHQRLP1zkcJmbM4sTRxnc107qumJ-va_8AIw2pnQZCybXxRZ3yW1Ev1QxW4IaXW9r"
            logoSrc={FBLogo}
            title="Agency"
            logoStyle={logoStyle2}
          />
          <Logo 
            href="https://instagram.com/molinasnutricion/"
            logoSrc={IGLogo}
            title="Agency"
            logoStyle={logoStyle2}
          />
          <Logo 
            href="https://youtube.com/channel/UCRMWUshZeyJ3AcAoUhxWegg"
            logoSrc={YTLogo}
            title="Agency"
            logoStyle={logoStyle2}
          />
          </div>
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
          <div style={{backgroundColor: '#E7BACD',display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <p>Powered by<a style={{textDecoration:'underline'}} href="https://instagram.com/diametra_" target='_blank' rel="noopener noreferrer"> Diametra &copy;</a></p>
          </div>
          </>
    
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  logoStyle2: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    
    flexBox: true,
    flexDirection: 'row',
    justifyContent:'space-evenly',
    alignItems:'center',
    flexWrap: 'wrap',

    ml:[0,0,0,'10%'],
    mr:[0,0,0,'10%'],
  },
  // Footer col one style

  // Footer col two style
  colTwo: {

    flexBox: true,
    flexWrap: 'wrap',
    ml:['0%',0,0,0],
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '15%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '80px',
    mb: '15px',
    ml:'4%',
  },
  logoStyle2: {
    width: '40px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
