import React from 'react'
import PricingTable from '../Saas/PricingSection/pricing.style.js'
import { Form, Input, InputNumber, Button } from 'antd';
import { Tabs } from 'antd';
import Step3a from './Step3a'
import Step3b from './Step3b'
import Step3c from './Step3c'
import '../../../common/src/assets/css/multi-step.css'

const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} es requerido',
  types: {
    email: '${label} no es un email válido',
    number: 'No es un número válido',
  },
  number: {

  },
};

const Step3 = ({onButtonClick}) => {

  const onFinish = values => {
    console.log(values);
  };


  return (
    <>
    <h1 
    style={{fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    paddingTop:'1.25em',
    paddingBottom:'1.25em',
    fontSize:'40px',
    textAlign: 'center',
    lineHeight: '1.25'}}
    content='Elige tu plan'
    >Método de pago
    </h1>
    <PricingTable style={{padding:'0'}}>

      <Tabs defaultActiveKey="1" centered  style={{width:'100%'}}>
        
        <TabPane  tab="Tarjetas " key="1">
          <Step3a/>
        </TabPane>
        <TabPane tab="Oxxo Pay" key="2">
          <Step3b/>
        </TabPane>

        <TabPane  tab="Transferencia" key="3">
          <Step3c/>
        </TabPane>
      </Tabs>
    </PricingTable>
    
    </>
  )
}
export default Step3