import React, {useState, useEffect} from 'react'
import axios from 'axios'
import PricingTable from '../Saas/PricingSection/pricing.style.js'
import { Form, Input, InputNumber, Button, Select } from 'antd';
import ConektaHelper from '../../../helpers/ConektaHelper'


const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} es requerido',
  types: {
    email: '${label} no es un email válido',
    number: 'No es un número válido',
    string: 'Ingresa tu nombre',
  },
  number: {

  },
};

const Step3a = ({onButtonClick}) => {
  const [cardholderName, setCardholderName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
//console.log(ConektaHelper)

const onFinish = values => {
  ConektaHelper.getCardBrand(cardNumber)
  ConektaHelper.validateCardNumber(cardNumber)
    console.log(values);
  //  axios.get("/.netlify/functions/Conekta-order-creation")
  //.then((res) =>  console.log(res))
  //.catch(e => {
  //    console.log(e)})

  };

  const handleCardholderNameChange = (e) => {
    e.persist();
    setCardholderName(cardholderName => (e.target.value));
    console.log(cardholderName)
  }
  const handleCardNumberChange = (e) => {
    e.persist()
    setCardNumber(cardNumber => (e.target.value))
    console.log(cardNumber)
  }


  return (
    <>

    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap'}}>
    <PricingTable >
    <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
    <span className="card-errors"></span>
      <Form.Item name={'cardholderName'} label="Nombre en la tarjeta" rules={[{ required: true, type: 'string' }]}>
        <Input type='text' size="20" data-conekta="card[name]" onChange={handleCardholderNameChange}  placeholder='Nombre en la tarjeta' />
      </Form.Item>
      <Form.Item name={['user', 'cardNumber']} label="Número de tarjeta" rules={[{ required: true }]}>
        <Input type="text" size="20" data-conekta="card[number]" onChange={handleCardNumberChange}   placeholder='Número de tarjeta' />
      </Form.Item>
      <Form.Item name={['user', 'cardCVC']} label="CVC" rules={[{ required: true}]}>
        <Input type="text" size="4" data-conekta="card[cvc]" style={{width:'100%'}} placeholder='971' />
      </Form.Item>
      <Form.Item name={['user', 'cardExpMonth']} label="Mes expiración" rules={[{ required: true}]}>
      <Select type="text" size="2" data-conekta="card[exp_month]"   style={{ width: 120 }}>
      <Option value="01">01</Option>
      <Option value="02">02</Option>
      <Option value="03">03</Option>
      <Option value="04">04</Option>
      <Option value="05">05</Option>
      <Option value="06">06</Option>
      <Option value="07">07</Option>
      <Option value="08">08</Option>
      <Option value="09">09</Option>
      <Option value="10">10</Option>
      <Option value="11">11</Option>
      <Option value="12">12</Option>
    </Select>
      </Form.Item>
      <Form.Item name={['user', 'cardExpYear']} label="Año expiración" rules={[{ required: true }]}>
      <Select  type="text" size="4" data-conekta="card[exp_year]"  style={{ width: 120 }} >
      <Option value="2020">2020</Option>
      <Option value="2021">2021</Option>
      <Option value="2022">2022</Option>
      <Option value="2023">2023</Option>
      <Option value="2024">2024</Option>
      <Option value="2025">2025</Option>
      <Option value="2026">2026</Option>
      <Option value="2027">2027</Option>
      <Option value="2028">2028</Option>
    </Select>
      </Form.Item>

      <Form.Item  wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
        <Button  type="primary" htmlType="submit">
          Pagar
        </Button>
      </Form.Item>
    </Form>
    <a href={'https://help.conekta.com/hc/es-419/articles/360018086554--Qu%C3%A9-tipo-de-tarjetas-procesan-'} 
    rel={'noopener noreferrer'}
    target={'_blank'}
    style={{color:'black'}}>
      <p>¿Qué tarjetas aceptamos?</p>
    </a>
    </PricingTable>
    </div>
    
    </>
  )
}
export default Step3a