import React, {useContext, useEffect} from 'react'
import PricingTable from '../Saas/PricingSection/pricing.style.js'
import {CheckoutContext} from '../../../context/CheckoutContext.js'

const Step1 = ({onCardClick}) => {
  


  const data = useContext(CheckoutContext)
  console.log(data)
  const plan1 = {
    title: 'Plan Online',
    price: 400
  }
  const plan2 = {
    title: 'Plan Online ',
    price: 700
  }

  //onMouseDown={() => {setPlanD(objecto)}}
  return (
    <>
    <h1 
    style={{fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    paddingTop:'1.25em',
    paddingBottom:'1.25em',
    fontSize:'40px',
    textAlign: 'center',
    lineHeight: '1.25'}}
    content='Elige tu plan'
    >Elige tu plan
    </h1>
    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap'}}>
    <PricingTable style={{cursor:'pointer'}} onMouseDown={() => {data.planData =plan1}}  onClick={onCardClick}>
      <h1 className='plan-title' style={{textAlign:'center'}}>Plan Online <br/></h1>
      <p style={{textAlign:'center'}}>$400.00MXN</p>
    </PricingTable>

    <PricingTable style={{cursor:'pointer'}} onMouseDown={() => {data.planData =plan2}}  onClick={onCardClick}>
      <h1 className='plan-title' style={{textAlign:'center'}}>Plan Online <br/> personalizado<br/>para 2 personas</h1>
      <p style={{textAlign:'center'}}>$700.00MXN</p>
    </PricingTable>
    </div>
    </>
  )
}
export default Step1