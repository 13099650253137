import React, {useContext, useState} from 'react'
import axios from 'axios'
import PricingTable from '../Saas/PricingSection/pricing.style.js'
import { Form, Input, InputNumber, Button } from 'antd';
import {CheckoutContext} from '../../../context/CheckoutContext.js'

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} es requerido',
  types: {
    email: '${label} no es un email válido',
    number: 'No es un número válido',
  },
  number: {

  },
};

const Step2 = ({onButtonClick}) => {

  const [nameInput, setNameInput] = useState({})
  const [mailInput, setMailInput] = useState({})
  const [phoneInput, setPhoneInput] = useState({})
  const data = useContext(CheckoutContext)
  console.log(data)


  const onFinish = values => {
    data.userData = {
      name: nameInput,
      email: mailInput,
      phone: phoneInput
    }
        axios.post("/.netlify/functions/Conekta-order-creation",data)
  .then((res) =>  
  window !== undefined ?

    window.location = res.data.url
    : ''
  )
  .then((text) => console.log(text))
  .catch(e => {
      console.log(e)})

    console.log(data)
  };

  const handleNameChange = (e) => {
    e.persist();
    setNameInput(nameInput => (e.target.value));
    console.log(nameInput)
  }
  const handleMailChange = (e) => {
    e.persist();
    setMailInput(mailInput => ( e.target.value));
    console.log(mailInput)
  }
  const handlePhoneChange = (e) => {
    e.persist();
    setPhoneInput(phoneInput => (e.target.value));
    console.log(phoneInput)
  }


  return (
    <>
    <h1 
    style={{fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    paddingTop:'1.25em',
    paddingBottom:'1.25em',
    fontSize:'40px',
    textAlign: 'center',
    lineHeight: '1.25'}}
    content='Elige tu plan'
    >Información de contacto
    </h1>
    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap'}}>
    <PricingTable >
    <Form {...layout} name="nest-messages"  onFinish={onButtonClick} validateMessages={validateMessages}>
      <Form.Item name={'name'} label="Nombre" rules={[{ required: true }]}>
        <Input onChange={handleNameChange} placeholder='Nombre Apellidos' />
      </Form.Item>
      <Form.Item name={'email'} label="Email" rules={[{ required: true, type: 'email' }]}>
        <Input onChange={handleMailChange} placeholder='tucorreo@gmail.com' />
      </Form.Item>
      <Form.Item name={'phone'} label="Whatsapp" rules={[{ required: true}]}>
        <Input maxLength={10} onChange={handlePhoneChange} style={{width:'100%'}} placeholder='10 números' />
      </Form.Item>

      <Form.Item  wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
        <Button onClick={onFinish}   type="primary" htmlType="submit">
          Generar link de pago
        </Button>
      </Form.Item>
    </Form>
    </PricingTable>
    </div>
    
    </>
  )
}
export default Step2